.fallback-spinner {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
}

.ds-loading  {
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
}

.ds-loading .cls-1{
    opacity:0;
    animation: anime-cls1 2s cubic-bezier(0,0,0.58,1) 0s infinite;
}
.ds-loading .cls-2{stroke:#a41afa; fill: transparent; stroke-width:20px;}
.ds-loading .cls-3{
    opacity:0;
    animation: anime-cls3 2s cubic-bezier(0,0,0.58,1) 0.1s infinite;
}
.ds-loading .cls-4 {
    opacity:0;
    animation: anime-cls4 2s cubic-bezier(0,0,0.58,1) 0.2s infinite;
}
@keyframes anime-cls1 {
    0% {
        transform: translate(-120px, 120px);
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    50% {
        opacity: 0.25;
        transform: translate(0, 0);
    }
    80% {
        opacity: 0;
    }
}
@keyframes anime-cls3 {
    0% {
        transform: translate(-100px, 100px);
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    60% {
        opacity: 0.5;
        transform: translate(0, 0);
    }
    90% {
        opacity: 0;
    }
}
@keyframes anime-cls4 {
    0% {
        transform: translate(-80px, 80px);
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    70% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
    }
}